(function ($) {

  function selectSize(sizeElement) {

    // Get your classes straight
    $('.select_size').not(sizeElement).removeClass('checked');

    $(sizeElement).addClass('checked');

    // Check associated checkbox
    $(sizeElement).find('input:radio').prop('checked', true);

    // update the klarna installment price
    let installmentPrice = (parseFloat($(sizeElement).find('.option_price').text().replace(/[$C]/g, '')) / 4).toFixed(2);
    let klarnaInstallmentsContainer = $('.klarna-installment-price');
    klarnaInstallmentsContainer.html('$' + installmentPrice);

    // Fill in the appropriate message
    var message = $(sizeElement).data('message');
    $('#priceDescription').html(message);

    // replace image
    var image_class = $(sizeElement).data('imageclass');

    if ($("#" + image_class).val()) {

      // Load up the appropriate image for the appropriate size (if available)
      if ($('img.' + image_class).length) {

        $('.single-product-image').hide();
        $('img.' + image_class).show();

      } else if ($("#" + image_class).length) {

        $('.single-product-image').hide();
        $('.product__image-container, .modal-image-container').prepend($("#" + image_class).val());

      }

    }

    // Fill in the correct SKU info (if it exists)
    var new_sku = $(sizeElement).data('sku');

    if (new_sku) {
      $('#prodViewId').html(new_sku);
    }

  }

  // When an a size option is clicked
  $('li.select_size').on('click', function () {

    selectSize(this);

  });

  // Default to premium for valentines day
  var variation = 'standard';
  var hasDeluxe = $('#deluxe_option').length;
  if (hasDeluxe) {
    variation = 'deluxe';
  }

  // Default size option
  selectSize('#' + variation + '_option');

})(jQuery);