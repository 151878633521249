import {
  getDaysInMonth,
  addMonths,
  addDays,
  format as formatDefault,
} from "date-fns";

import enUS from "date-fns/locale/en-US";
import frCA from "date-fns/locale/fr-CA";

import { format, utcToZonedTime } from "date-fns-tz";

import {
  chooseClosestAvailableDate,
  openProductDatepicker,
} from "../chooseClosestAvailableDate";

(function ($, chooseClosestAvailableDate, openProductDatepicker, dateService) {
  "use strict";

  var viewDatesText = "More Dates";
  var datesResponse;

  var productBase64EncodedJsonElement = document.getElementById("product_json");
  var productBase64EncodedJson =
    productBase64EncodedJsonElement && productBase64EncodedJsonElement.value;
  var product = productBase64EncodedJson
    ? JSON.parse(atob(productBase64EncodedJson))
    : [];

  var todayDays = dateService.getDaysInMonth(new Date());
  var nextMonthDays = dateService.getDaysInMonth(
    dateService.addMonths(new Date(), 1)
  );
  var twoMonthsDays = dateService.getDaysInMonth(
    dateService.addMonths(new Date(), 2)
  );

  var maxDate = todayDays + nextMonthDays + twoMonthsDays;

  function setDates(response) {
    initDatePickers();
    initDatesAndType(response);
  }

  function loadDates(elem) {
    // console.log('loadDates 1', elem);
    var loadPromise = new Promise((resolve) => {
      // console.log(product.id);
      var datesClosed = $.ajax({
        url: "/storefronts/datesClosed?product-id=" + product.id,
        dataType: "json",
      });

      datesClosed.done(function (response) {
        // console.log('loadDates 2', response);
        // refresh the cal dates in case any
        // info changed while customer on this page
        // target both elements
        if (elem) {
          // console.log('loadDates 3', elem);
          $(elem).datepicker("refresh");
          $("#ui-datepicker-div").attr("notranslate", "");
        }
        datesResponse = response;

        return resolve(response);
      });
    });
    // console.log('loadDates 4', loadPromise);
    return loadPromise;
  }

  function handleDeliveryDisabled(datesResponse) {
    if (!datesResponse.delivery_disabled) {
      return;
    }

    $("#delivery_dates_container .ui-datepicker-trigger").removeClass("active");
    $("#delivery_dates_container .ui-datepicker-trigger").addClass("grayedOut");
    $("#delivery_dates_container .ui-datepicker-trigger").attr(
      "tabindex",
      "-1"
    );
    $("#delivery_dates_container .ui-datepicker-trigger").attr(
      "aria-disabled",
      "true"
    );
    $("#delivery_dates_container .ui-datepicker-trigger").attr(
      "disabled",
      true
    );
  }

  function handlePickupDisabled(datesResponse) {
    // Hide pickup dates if the closed dates are more than the four upcoming days
    if (datesResponse.pickup_disabled) {
      return;
    }

    $(
      "#pickupTab, #pickup_dates_container, .product__datepicker-tabs-container"
    ).removeClass("hide");

    // Remove styling from the dates container since no tabs
    $("#delivery_dates_container").addClass(
      "product__datepicker-days-container"
    );

    //remove it if the pickup is enabled, and instead show it after pickup tab.
    $(".product__datepicker-free-delivery.no-pickup").removeClass("no-pickup");
  }

  function initSubmitHandler() {
    // Prevent form from submitting if a date hasn't been selected
    $("#placeOrder").submit(function () {
      if (
        ($("#o_type").val() === "localdelivery" &&
          !$("#hidden_delivery_date").val()) ||
        ($("#o_type").val() === "pickup" && !$("#hidden_pickup_date").val())
      ) {
        return false;
      }

      sessionStorage.removeItem("lv.useCode");
    });
  }

  function handleDeliveryToday(datesResponse) {
    // Remove active class from today date
    // Add grayed out class to today date
    if (!datesResponse.delivery_today) {
      ////  console.log('delivery_today', datesResponse.delivery_today);

      $("#delivery_datepicker .todayDate").removeClass("active");
      $("#delivery_datepicker .todayDate").addClass("grayedOut");
      $("#delivery_datepicker .todayDate").attr("aria-disabled", "true");
      $("#delivery_datepicker .todayDate").attr("tabindex", "-1");

      if (datesResponse.pickup_today) {
        $(".no-pickup").hide();
        $(".need-it-today").removeClass("hide");
        $("#delivery_dates_container").css("padding-bottom", "30px");
      }
    }
  }

  function handlePickupToday(datesResponse) {
    // Remove active class
    // add grayed out class
    if (!datesResponse.pickup_today) {
      $("#pickup_datepicker .todayDate").removeClass("active");
      $("#pickup_datepicker .todayDate").addClass("grayedOut");
      $("#pickup_datepicker .todayDate").attr("aria-disabled", "true");
      $("#pickup_datepicker .todayDate").attr("tabindex", "-1");
      $(".product__datepicker-pickup-cutoff").remove();

      return;
    }

    $("#pickup_cutoff").text(datesResponse.pickup_cutoff);

    // Set up timer if pickup is today
    $("#pickup_datepicker .todayDate").addClass("active");
    $("#pickup_datepicker .todayDate").removeClass("grayedOut");
    $("#pickup_datepicker .todayDate").attr("aria-disabled", "false");
  }

  // Timer toggling
  function deliveryTypeTabHandler(datesResponse) {
    //console.log('check here 0', datesResponse);
    if (datesResponse.delivery_today) {
      // console.log('delivery_today', datesResponse.delivery_today);
      $("#delivery_dates_container").css("padding-bottom", "50px");
    }

    // if datesResponse.delivery_today is true or fc-redirect__container is present, then hide .product__datepicker-free-delivery
    if (datesResponse.delivery_today) {
      $(".product__datepicker-free-delivery.mobile").addClass("hide");
      $(".product__datepicker-free-delivery.desktop").addClass("hide");
    }

    // remove green delivery text if fc-redirect__container is present
    if ($(".fc-redirect__container").length) {
      $(".product__datepicker-delivery-cutoff").addClass("hide");
      $(".product__datepicker-free-delivery.mobile").addClass("hide");
      $(".product__datepicker-free-delivery.desktop").addClass("hide");
    }

    // Toggle off whatever copy shouldn't be shown based on current o_type
    $(
      $("#o_type").val() === "localdelivery"
        ? ".product__datepicker-pickup-cutoff"
        : ".product__datepicker-free-delivery"
    ).addClass("hide");
    $("#deliveryTab, #pickupTab").click(function () {
      var type = $(this).data("ordertype");
      var otherType = type === "delivery" ? "pickup" : "delivery";

      if ($(this).hasClass("active")) {
        return;
      }

      // Get tab and tab contents, toggle active class
      $(
        "#" +
          type +
          "_dates_container, #" +
          otherType +
          "_dates_container, #" +
          type +
          "Tab, #" +
          otherType +
          "Tab"
      ).toggleClass("active");
      $(
        type === "delivery"
          ? ".product__datepicker-pickup-cutoff"
          : ".product__datepicker-free-delivery"
      ).addClass("hide");
      $(
        type === "delivery"
          ? ".product__datepicker-delivery-cutoff"
          : ".product__datepicker-delivery-cutoff"
      ).removeClass("hide");

      if (type !== "delivery") {
        $(".product__datepicker-pickup-cutoff").removeClass("hide");
        $(".product__datepicker-delivery-cutoff").addClass("hide");
        $("#pickup_dates_container").css("padding-bottom", "10px");

        if (datesResponse.pickup_today) {
          $("#pickup_dates_container").css("padding-bottom", "50px");
        } else if (!datesResponse.pickup_today) {
          $("#pickup_dates_container").css("padding-bottom", "10px");
        }
      } else {
        // console.log('CHECK HERE 1', datesResponse);

        $("#pickup_dates_container").css("padding-bottom", "0px");
        $("#delivery_dates_container").css("padding-bottom", "50px");

        $(".product__datepicker-free-delivery").removeClass("hide");
        if (!datesResponse.delivery_today && datesResponse.pickup_today) {
          // console.log('CHECK HERE 2', datesResponse);

          //no delivery but pickup
          $(".no-pickup").hide();
          $(".need-it-today").removeClass("hide");
          $("#delivery_dates_container").css("padding-bottom", "30px");
        } else {
          if (datesResponse.delivery_today) {
            // console.log('CHECK HERE 3', datesResponse);

            //both delivery and pickup
            $("#delivery_dates_container").css("padding-bottom", "50px");
            $(".need-it-today").addClass("hide");
            $(".product__datepicker-free-delivery.mobile").addClass("hide");
            $(".product__datepicker-free-delivery.desktop").addClass("hide");

            if (datesResponse.pickup_disabled) {
              // console.log('CHECK HERE 4', datesResponse);

              $(".product__datepicker-delivery-cutoff").css("top", "84px");
            }
          } else if (!datesResponse.delivery_today) {
            // console.log('CHECK HERE 5', datesResponse);
            $("#delivery_dates_container").css("padding-bottom", "30px");

            $(".no-pickup").removeClass("hide");
            $(".need-it-today").addClass("hide");

            if ($(".no-free-delivery").length) {
              // console.log('CHECK HERE 6', datesResponse);
              $("#delivery_dates_container").css("padding-bottom", "10px");
            }

            if ($(".fc-redirect__container").length) {
              $(".product__datepicker-free-delivery.mobile").addClass("hide");
              $(".product__datepicker-free-delivery.desktop").addClass("hide");
              $(".product__datepicker-delivery-cutoff").addClass("hide");
            }
          }
        }
      }

      // Don't change o_type if the type is disabled
      if (
        (type === "delivery" && datesResponse.delivery_disabled) ||
        (type === "pickup" && datesResponse.pickup_disabled)
      ) {
        return;
      }

      $("#o_type").val(type === "delivery" ? "localdelivery" : "pickup");
      $(".alcohol-disclaimer-o_type").text(
        type.charAt(0).toUpperCase() + type.slice(1)
      );
      // get the delivery_dates_container and if there is class active on it, loop through the nested children and
      // add tabindex="0" to a tag that has class dateLink and active class on it and button element
      if ($("#delivery_dates_container").hasClass("active")) {
        $("#delivery_dates_container")
          .find("a.dateLink:not(.grayedOut), button.ui-datepicker-trigger")
          .attr("tabindex", "0");

        // Set tabindex="-1" for grayedOut links
        $("#delivery_dates_container")
          .find("a.dateLink.grayedOut")
          .attr("tabindex", "-1");
        $("#pickup_dates_container")
          .find("a.dateLink, button.ui-datepicker-trigger")
          .attr("tabindex", "-1");
      } else if ($("#pickup_dates_container").hasClass("active")) {
        $("#pickup_dates_container")
          .find("a.dateLink:not(.grayedOut), button.ui-datepicker-trigger")
          .attr("tabindex", "0");

        // Set tabindex="-1" for grayedOut links
        $("#pickup_dates_container")
          .find("a.dateLink.grayedOut")
          .attr("tabindex", "-1");
        // remove tab from delivery dates container
        $("#delivery_dates_container")
          .find("a.dateLink, button.ui-datepicker-trigger")
          .attr("tabindex", "-1");
      }
    });
  }

  function initDatesAndType(datesResponse) {
    handleDeliveryToday(datesResponse);

    handlePickupToday(datesResponse);

    handlePickupDisabled(datesResponse);

    assignActiveDates(datesResponse);

    initSubmitHandler();

    deliveryTypeTabHandler(datesResponse);

    $(window).resize(function () {
      if (
        $(".ui-datepicker-trigger").html() === "More Dates" ||
        $(".ui-datepicker-trigger").html() === "View More Dates"
      ) {
        const datesText =
          $(window).width() <= 720 ? "More Dates" : "More Dates";
        $(".ui-datepicker-trigger").html(datesText);
      }
    });

    // Change region if canadian
    $("#delivery_datepicker_input, #pickup_datepicker_input").datepicker(
      "option",
      $("#isUserLocalFrench").val()
        ? $.datepicker.regional["fr-CA"]
        : $.datepicker.regional[""]
    );

    // Clicking the date buttons will add the date to the input field
    $("#delivery_datepicker_input").val("");
    $("#pickup_datepicker_input").val("");

    datelinkClickHandler();

    needItTodayClickHandler();

    // Check to the delivery today field, disable today's date if delivery today has passed
    chooseClosestAvailableDate("delivery", datesResponse);

    if (!datesResponse.pickup_disabled) {
      // Check to the pickup today field, disable today's date if pickup today has passed
      chooseClosestAvailableDate("pickup", datesResponse);
    }

    openProductDatepicker(
      $("#o_type").val() === "localdelivery" ? "delivery" : "pickup"
    );

    handleDeliveryDisabled(datesResponse);
  }

  function assignActiveDates(datesResponse) {
    // Disable date buttons if the store is not accepting delivery/pickup on that day
    $("#pickup_datepicker .dateLink, #delivery_datepicker .dateLink").each(
      function () {
        var dateCheck = $(this).data("datecheck");

        var type = $(this).data("ordertype");

        var typeArray =
          type === "delivery"
            ? datesResponse.no_delivery_dates
            : datesResponse.no_pickup_dates;

        $("a." + type + "_datelink").each(function () {
          // At least one day isn't grayed out

          if ($(this).hasClass("grayedOut")) {
            return;
          }
        });

        if (typeArray.indexOf(dateCheck) !== -1) {
          $(this).addClass("grayedOut");
          $(this).attr("aria-disabled", "true");
          $(this).attr("tabindex", "-1");
        }
      }
    );
  }

  function needItTodayClickHandler() {
    $(".need-it-today").click(function () {
      $("#pickupTab").click();
    });
  }

  function datelinkClickHandler() {
    // When you click on a  date button (calendar)
    $(".dateLink").click(function () {
      var type = $(this).data("ordertype");

      // If grayedOut (disabled) do nothing
      if ($(this).hasClass("grayedOut")) {
        return;
      }

      // Add active
      $(this).addClass("active");
      console.log($(this));
      // Remove active class from other links
      $("#" + type + "_datepicker .dateLink")
        .not(this)
        .removeClass("active");

      // Fill hidden date field
      var dateData = $(this).data("datecheck");
      $("#hidden_" + type + "_date").val(dateData);

      // Reset calendar button trigger if it's not the one being clicked
      if ($(this).hasClass("date-picker-non-calendar")) {
        $("#" + type + "_datepicker_input").val("");
        $("#" + type + "_datepicker .ui-datepicker-trigger").removeClass(
          "active"
        );
      }

      return type === "delivery"
        ? localStorage.setItem("deliveryDate", dateData)
        : localStorage.setItem("pickupDate", dateData);
    });
  }

  function dateIsSelectable(date, type) {
    var formattedDate = dateService.formatDefault(date, "yyyy-MM-dd");
    // console.log('dateIsSelectable response', datesResponse, formattedDate);
    var closed =
      type === "delivery"
        ? datesResponse.no_delivery_dates.find((ndd) => ndd === formattedDate)
        : datesResponse.no_pickup_dates.find((ndd) => ndd === formattedDate);

    var isOpenIsToday =
      !closed &&
      dateService.formatDefault(new Date(), "yyyy-MM-dd") === formattedDate;

    var isSelectable = isOpenIsToday
      ? [
          type === "delivery"
            ? datesResponse.delivery_today
            : datesResponse.pickup_today,
        ]
      : [!closed, ""];

    // check the setting in the nodeliverytoday var
    return isSelectable;
  }

  // compare the date with the array
  function deliveryDateIsSelectable(date) {
    return dateIsSelectable(date, "delivery");
  }

  function pickupDateIsSelectable(date) {
    return dateIsSelectable(date, "pickup");
  }

  function pickupDateSelect(date) {
    dateSelect(date, "pickup");
  }

  function deliveryDateSelect(date) {
    dateSelect(date, "delivery");
  }

  function dateSelect(date, type) {
    localStorage.setItem(
      type === "delivery" ? "deliveryDate" : "pickupDate",
      date
    );

    // Remove active class from all links
    $.each($("#" + type + "_datepicker .dateLink"), function (index, value) {
      if ($(value).hasClass("active")) {
        $(value).removeClass("active");
      }
    });

    // Replace value with date, add active class
    // Remove 20 from 20XX to make short enough to not wrap in container
    var datestring = date;

    if (date.indexOf("/") !== -1) {
      datestring = date.split("/");
      datestring[2] = datestring[2].replace("20", "");
      datestring = datestring.join("/");
    }

    $("#" + type + "_datepicker .ui-datepicker-trigger")
      .html(datestring)
      .addClass("active");
  }

  function initDatePickers() {
    // console.log('initDatePickers');
    // target both elements
    $("#delivery_datepicker_input").datepicker({
      showOn: "button",
      buttonText: viewDatesText,
      beforeShow: loadDates,
      minDate: "0d",
      maxDate: "+" + maxDate.toString() + "d",
      beforeShowDay: deliveryDateIsSelectable,
      showOtherMonths: true,
      selectOtherMonths: true,
      showAnim: "fold",
      altField: "#hidden_delivery_date",
      altFormat: "yy-mm-dd",
      onSelect: deliveryDateSelect,
    });

    $("#pickup_datepicker_input").datepicker({
      showOn: "button",
      buttonText: viewDatesText,
      beforeShow: loadDates,
      minDate: "0d",
      maxDate: "+" + maxDate.toString() + "d",
      beforeShowDay: pickupDateIsSelectable,
      showOtherMonths: true,
      selectOtherMonths: true,
      showAnim: "fold",
      altField: "#hidden_pickup_date",
      altFormat: "yy-mm-dd",
      onSelect: pickupDateSelect,
    });
  }

  function initVirtualSubmitHandler() {
    $("#placeOrder").submit(function () {
      $("#o_type").val("localdelivery");
      $("#hidden_delivery_date").val("2038-01-19");

      sessionStorage.removeItem("lv.useCode");
    });
  }

  function renderDates(type) {
    const tzString = $("#store_timezone_string").val();
    const storeLocale = $("#isUserLocalFrench").val() ? frCA : enUS;
    const formatOpts = {
      timeZone: tzString,
      locale: storeLocale,
    };

    let html = "";

    // Render three days and then a datepicker
    for (let index = 0; index < 3; index++) {
      const adjustedDate =
        index === 0 ? new Date() : dateService.addDays(new Date(), index);
      const zonedDate = dateService.utcToZonedTime(adjustedDate, tzString);

      html += `<a class="${index === 0 ? "todayDate" : ""} ${
        type + "_datelink"
      } dateLink date-picker-non-calendar"
        data-ordertype="${type}"
        data-datecheck="${dateService.format(
          zonedDate,
          "yyyy-MM-dd",
          formatOpts
        )}">
        <span class="datelink-copy" notranslate>
          ${
            index === 0
              ? "Today"
              : dateService.format(zonedDate, "EEE", formatOpts)
          }
          <br>
          <span notranslate>
          ${dateService.format(zonedDate, "MMM d", formatOpts)}
          </span>
        </span>
      </a>`;
    }

    $(`#${type}_datepicker`).prepend($.parseHTML(html));

    return Promise.resolve();
  }

  function init() {
    renderDates("delivery")
      .then(() => renderDates("pickup"))
      .then(() => {
        if (product.is_virtual) {
          $("#hidden_delivery_date").val("");
          initVirtualSubmitHandler();
        } else {
          // console.log('init loadDates');
          loadDates().then((res) => {
            // console.log('init loadDates 5', res);
            setDates(res);
          });
        }
      })
      .catch((e) => console.error(e));

    // get the delivery_dates_container and if there is class active on it, loop through the nested children and
    // add tabindex="0" to a tag that has class dateLink and active class on it and button element
    if ($("#delivery_dates_container").hasClass("active")) {
      $("#delivery_dates_container")
        .find("a.dateLink:not(.grayedOut), button.ui-datepicker-trigger")
        .attr("tabindex", "0");

      // Set tabindex="-1" for grayedOut links
      $("#delivery_dates_container")
        .find("a.dateLink.grayedOut")
        .attr("tabindex", "-1");
      $("#pickup_dates_container")
        .find("a.dateLink, button.ui-datepicker-trigger")
        .attr("tabindex", "-1");
    } else if ($("#pickup_dates_container").hasClass("active")) {
      $("#pickup_dates_container")
        .find("a.dateLink:not(.grayedOut), button.ui-datepicker-trigger")
        .attr("tabindex", "0");

      // Set tabindex="-1" for grayedOut links
      $("#pickup_dates_container")
        .find("a.dateLink.grayedOut")
        .attr("tabindex", "-1");
      // remove tab from delivery dates container
      $("#delivery_dates_container")
        .find("a.dateLink, button.ui-datepicker-trigger")
        .attr("tabindex", "-1");
    }
  }

  init();
})(jQuery, chooseClosestAvailableDate, openProductDatepicker, {
  getDaysInMonth,
  addMonths,
  addDays,
  format,
  formatDefault,
  utcToZonedTime,
});
