(function ($) {

  var brands = {
    1: 'UFN',
    2: '1800',
    4: 'FTD',
    5: 'TF',
    6: 'LV'
  };

  var dataLayer = window.dataLayer = window.dataLayer || [];

  pushReviews();

  pushProductData();

  function pushReviews() {
    var reviewsBase64EncodedJsonElement = document.getElementById('reviewsJson');
    var reviewsBase64EncodedJson = reviewsBase64EncodedJsonElement && reviewsBase64EncodedJsonElement.value;
    var reviews = reviewsBase64EncodedJson ? JSON.parse(atob(reviewsBase64EncodedJson)) : [];

    for (var i = 0; i < reviews.length; i++) {

      dataLayer.push({
        event: 'review.loaded',
        review: reviews[i]
      });

    }
  }

  function pushProductData() {
    var productBase64EncodedJsonElement = document.getElementById('product_json');
    var productBase64EncodedJson = productBase64EncodedJsonElement && productBase64EncodedJsonElement.value;
    var product = productBase64EncodedJson ? JSON.parse(atob(productBase64EncodedJson)) : [];

    var productList = [];

    if (product.is_enabled_premium) {
      productList.push({
        name: product.Product.name,
        id: product.Product.id,
        price: product.Product.price_premium,
        brand: brands[product.Product.selection_guide_id],
        category: 'main',
        variant: 'premium',
      });
    }

    if (product.is_enabled_deluxe) {
      productList.push({
        name: product.Product.name,
        id: product.Product.id,
        price: product.Product.price_deluxe,
        brand: brands[product.Product.selection_guide_id],
        category: 'main',
        variant: 'deluxe',
      });
    }

    productList.push({
      name: product.Product.name,
      id: product.Product.id,
      price: product.Product.price,
      brand: brands[product.Product.selection_guide_id],
      category: 'main',
      variant: 'standard',
    });

    var impressionProducts = window.productList.map((product, index) => {
      return ({
        name: product.name,
        id: product.id,
        price: product.price,
        brand: brands[product.selection_guide_id],
        category: 'main',
        variant: 'standard',
        list: 'product_also_likes',
        position: index + 1
      });
    });

    dataLayer.push({
      event: 'lv.storefront.productsDetailsView',
      ecommerce: {
        impressions: impressionProducts,
        detail: {
          actionField: {
            // OLD LIST! Reset after this is pushed
            list: sessionStorage.getItem('lv.ecommerce.list') || ''
          },
          products: productList
        }
      }
    });

  }

})(jQuery);