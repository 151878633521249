"use strict";

window.$ = window.jQuery = $;

import "./single-product.js";
import "./product-pricepicker.js";
import "./product-datepicker.js";
import "./ga-tracker.js";
import "./bing-tracker.js";
import "./product-datalayer.js";
